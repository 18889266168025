/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import {
  Avatar_02,
  Avatar_16,
  User1,
  User2,
  User3,
  User4,
} from "../../../Routes/ImagePath";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import { format } from "date-fns";

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import EditUserModal from "../../../components/Administration/Users/EditUseModal";
import EditColaboradorModal from "../../../components/Administration/Users/EditColaboradorModal";
import AddColaboradorModal from "../../../components/Administration/Users/AddColaboradorModal";
import PesquisarColaboradorModal from "../../../components/modelpopup/PesquisarColaboradorModal";
import FechoDeContasModal from "../../../components/modelpopup/FechoDeContasModal";

const Profile = () => {
  const { t } = useTranslation();

  let { userID } = useParams();

  const [projetoSelecionado, setProjetoSelecionado] = useState();
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [utilizadores, setUtilizadores] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [utilizador, setUtilizador] = useState([]);
  const [faltas, setFaltas] = useState([]);
  const [ferias, setFerias] = useState([]);
  const [dividas, setDividas] = useState([]);
  const [faltasAnual, setFaltasAnual] = useState([]);
  const [feriasAnual, setFeriasAnual] = useState([]);
  const [missoes, setMissoes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user_permissoes,
    verificarPermissao,
    getToken,
    user,
    getMissao,
  } = AuthUser();
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [atividades, setAtividades] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [accoes, setAccoes] = useState([]);

  const [salarios, setSalarios] = useState([]);
  const [salario, setSalario] = useState([]);
  const [agregados, setAgregados] = useState([]);

  const navigate = useNavigate();
  const [projetos, setProjetos] = useState([]);

  useEffect(() => {
    if (verificarPermissao("COLABORADORES", "atualizar") || user.tipo <= 2 || user.id == userID) {      

      const fetchData = async () => {
        try {
          // Chama a função assíncrona getUtilizador()
          await getUtilizador();
          await getProjetos();
          await getUtilizadores();
          await getMissoes();
          await getTarefas();

          await getAccoes();

          await getCargos();
          await getDepartamentos();

          await getAtividades();
        } catch (error) {
          console.error("Erro ao obter utilizadores:", error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();

    } else {
      navigate("/admin-dashboard");
    }
  }, [userID]);

  const chamarModalfechoDeConta = () => {
    const editButton = document.getElementById("chamar_modal_fecho_de_conta");

    if (editButton) {
      editButton.click();
    }
  };

  const verFechoDeConta = async (colaborador) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("user_id", colaborador?.id);

      const response = await http.post("/fecho-conta/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      setLoading(false);
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const handleNavegaPerfilColaborador = (colaborador_id) => {
    navigate("/profile/" + colaborador_id);
  };

  const handleSubmissionSuccessProjeto = () => {
    getProjetos();
  };

  const handleSubmissionSuccess = (mensagem) => {
    getUtilizador();
  };

  const onSubmissionUserSuccess = (colaborador, arquivos) => {
    setUtilizador(colaborador);
    setArquivos(arquivos);
  };

  const getUtilizador = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizador/" + userID);

      setUtilizador(res?.data?.utilizador);
      setArquivos(res?.data?.arquivos);
      setFaltas(res?.data?.faltas);
      setDividas(res?.data?.dividas);
      setFaltasAnual(res?.data?.faltas_mensal);

      setSalario(res?.data?.salario);
      setAgregados(res?.data?.agregados);
      setSalarios(res?.data?.salarios);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getTarefas = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/tarefas_todas/" + getMissao()?.id);

      setTarefas(res.data.tarefas);

      setLoading(false);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/missoes_ativas");

      setMissoes(res.data.missoes);
      console.log("Missões 2024:", res.data.missoes);

      setLoading(false);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getProjetos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/projetos/" + getMissao()?.id);

      setProjetos(res.data?.projetos);

      console.log("projetos 2024:");
      console.log(projetos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getUtilizadores = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizadores/ativados/" + getMissao()?.id);

      setUtilizadores(res.data.utilizadores);

      console.log("Utilizadores 2024: ");
      console.log(res.data.utilizadores);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getAtividades = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizador/atividades/" + userID);

      setAtividades(res.data.atividades);

      console.log("atividades 2024: ");
      console.log(res.data.atividades);

      setLoading(false);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const chamarModalEdit = (idSelecionado) => {
    const editButton = document.getElementById("triggerModalEditUser");

    if (editButton) {
      editButton.click();
    }
  };

  const handleSubmissionSuccessUser = () => {
    const editButton = document.getElementById("closeButton2");

    if (editButton) {
      editButton.click();
    }

    getUtilizador();
  };

  const getCargos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cargos_ativos/" + getMissao()?.id);

      setCargos(res?.data?.cargos);

      console.log("Cargos Ativos: ");
      console.log(res?.data?.cargos);

      setLoading(false);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getDepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/departamentos_ativos/" + getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      console.log("Departamentos ativos: ");
      console.log(res?.data?.departamentos);

      setLoading(false);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get("/accoes");

      setAccoes(res?.data?.accoes);

      console.log("Acções 2024:");
      console.log(res?.data?.accoes);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false);
    }
  };


  const ativarColaborador = async (id, newState) => {
    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao("UTILIZADORES", "ativar")) {
      Swal.fire({
        title: "Confirmar",
        text: "Tem a certeza que pretende voltar a ativar este colaborador ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {
        //the start

        if (t.isConfirmed) {
          try {

            const formData = new FormData();
            formData.append("id", id);
            formData.append("estado", newState);

            const response = await http.post("/utilizador/activate", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });

            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            getUtilizador();


          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }

        //then ends
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Breadcrumbs
              maintitle={t("Profile")}
              title="Dashboard"
              subtitle={t("Profile")}
              modal="#find_colaborador"
            />
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-view">
                      <div className="profile-img-wrap">
                        <div className="profile-img">
                          <Link to="#">
                            <img
                              className="rounded-pill"
                              src={
                                url_root + "/storage/" + utilizador?.fotografia
                              }
                              alt="User Image"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="profile-info-left">
                              <h3 className="user-name m-t-0 mb-0">
                                {utilizador?.name}
                              </h3>
                              <h6 className="text-muted">
                                {utilizador?.departamento?.designacao}
                              </h6>
                              <small className="text-muted">
                                {utilizador?.cargo?.designacao}
                              </small>

                              <div className="staff-id">
                                {t("Employee ID")} : {utilizador?.id}
                              </div>
                              <div className="small doj text-muted">
                                {t("Mission")} :{" "}
                                {utilizador?.missao?.designacao}
                              </div>

                              <div className="staff-id">
                                BI / NIF - Validade
                              </div>
                              <div className="small doj text-muted">
                                {utilizador?.nif} -{" "}
                                {utilizador?.nif_expira
                                  ? format(
                                      new Date(utilizador?.nif_expira),
                                      "dd/MM/yyyy"
                                    )
                                  : utilizador?.nif_expira}
                              </div>

                              <div className="staff-id">Data Admissão</div>
                              <div className="small doj text-muted">
                                {utilizador?.admissao
                                  ? format(
                                      new Date(utilizador?.admissao),
                                      "dd/MM/yyyy"
                                    )
                                  : utilizador?.admissao}
                              </div>

                              {/* {utilizador?.id != user?.id ? (
                                <div className="staff-msg">
                                  <Link
                                    className="btn btn-custom"
                                    to={"/call/chat/1/" + utilizador?.id}
                                  >
                                    {t("Send Message")}
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )} */}

                              {utilizador?.estado == 1 ? (
                                <div className="staff-msg">
                                  <span
                                    className="btn btn-danger"
                                    title={utilizador?.name}
                                    onClick={() => chamarModalfechoDeConta()}
                                  >
                                    <i className="fa fa-file m-r-5" />{" "}
                                    {t("Encerrar contrato")}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}

                              {utilizador?.estado != 1 ? (
                                <div className="staff-msg" title="Este colaborador esta desativado">
                                  <span
                                    className="btn btn-dark"
                                    title={utilizador?.name}
                                    onClick={() => verFechoDeConta(utilizador)}
                                  >
                                    <i className="fa fa-times m-r-5" />{" "}
                                    {t("Desativado, ver fecho de conta")}
                                  </span>
                                  {" "}{" "}
                                  <span
                                    className="btn btn-success"
                                    title={utilizador?.name}
                                    onClick={() =>
                                      ativarColaborador(
                                        utilizador?.id,
                                        utilizador?.estado == 1 ? 0 : 1
                                      )
                                    }
                                  >
                                    <i className="fa fa-check m-r-5" />{" "}
                                    {t("Ativar agora")}
                                  </span>

                                </div>
                              ) : (
                                ""
                              )}

                            </div>
                          </div>
                          <div className="col-md-7">
                            <ul className="personal-info">
                              <li>
                                <div className="title">{t("Phone")}:</div>
                                <div className="text">
                                  <Link to={`tel:${utilizador?.telefone}`}>
                                    {utilizador?.telefone}
                                  </Link>
                                </div>
                              </li>
                              <li>
                                <div className="title">Email:</div>
                                <div className="text">
                                  <Link to={`mailto:${utilizador?.email}`}>
                                    {utilizador?.email}
                                  </Link>
                                </div>
                              </li>
                              <li>
                                <div className="title">{t("Birthday")}:</div>
                                <div className="text">
                                  {utilizador.data_nasc
                                    ? format(
                                        new Date(utilizador.data_nasc),
                                        "dd/MM/yyyy"
                                      )
                                    : utilizador.data_nasc}
                                </div>
                              </li>
                              <li>
                                <div className="title">{t("Address")}:</div>
                                <div className="text">
                                  {utilizador?.endereco}
                                </div>
                              </li>
                              <li>
                                <div className="title">{t("Gender")}:</div>
                                <div className="text">
                                  {utilizador?.genero == 1
                                    ? "Feminino"
                                    : "Masculino"}
                                </div>
                              </li>
                              <li>
                                {/* <div className="title">{t('Reports to')}:</div>
                              <div className="text">
                                <div className="avatar-box">
                                  <div className="avatar avatar-xs">
                                    <img src={Avatar_16} alt="User Image" />
                                  </div>
                                </div>
                                <Link to="profile">
                                  {userData.supervisor.name}
                                </Link>
                              </div> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="pro-edit">
                        <span
                          className="edit-icon"
                          onClick={() => chamarModalEdit(utilizador)}
                        >
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card tab-box">
              <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item">
                      <Link
                        to="#emp_profile"
                        data-bs-toggle="tab"
                        className="nav-link active"
                      >
                        <i className="fa fa-user"></i> {t("Profile")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_faltas"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="fa fa-calendar"></i> {t("Faltas")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="#emp_dividas"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="la la-money text-danger"></i>{" "}
                        {t("Dividas")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_salarios"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="la la-money text-success"></i>{" "}
                        {t("Salários")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_ferias"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="fa fa-umbrella"></i> {t("Ferias")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_projects"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="la la-rocket" /> {t("Projects")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#bank_statutory"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="fa fa-check" /> {t("Tasks")}
                        {/* <small className="text-danger ms-1">(Admin Only)</small> */}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#tab_activities"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="fa fa-list" /> {t("Activities")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Profile Info Tab */}
            <ProfileTab
              projetos={projetos}
              tarefas={tarefas}
              utilizadores={utilizadores}
              arquivos={arquivos}
              missoes={missoes}
              utilizador={utilizador}
              faltas={faltas}
              ferias={ferias}
              dividas={dividas}
              faltasAnual={faltasAnual}
              feriasAnual={feriasAnual}
              atividades={atividades}
              salariosP={salarios}
              salarioP={salario}
              agregadosP={agregados}
              onSubmissionSuccess={handleSubmissionSuccessProjeto}
              onSubmissionUserSuccess={onSubmissionUserSuccess}
            />

            <PesquisarColaboradorModal
              onSubmissionSuccess={handleNavegaPerfilColaborador}
              utilizadores={utilizadores}
            />

            <EditColaboradorModal
              onSubmissionSuccess={handleSubmissionSuccess}
              utilizador={utilizador}
              generoParam={utilizador?.genero}
              nivelParam={utilizador?.nivel_id}
              accoes={accoes}
              cargos={cargos}
              departamentos={departamentos}
              missoes={missoes}
              imagePreview={url_root + "/storage/" + utilizador?.fotografia}
            />

            <AddColaboradorModal
              onSubmissionSuccess={handleSubmissionSuccess}
              accoes={accoes}
              cargos={cargos}
              departamentos={departamentos}
              missoes={missoes}
            />

            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_user"
              id="triggerModalEditUser"
            ></span>

            <FechoDeContasModal colaborador={utilizador} />

            <span
              data-bs-toggle="modal"
              data-bs-target="#fecho_de_conta_modal"
              id="chamar_modal_fecho_de_conta"
            ></span>
          </div>
        </div>
      </>
    );
};

export default Profile;

/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import notifications from "../../assets/json/notifications";
import message from "../../assets/json/message";
import {
  Applogo,
  logoArteFe,
  User1,
  Avatar_02,
  headerlogo,
  lnEnglish,
  lnAngola,
  lnFrench,
  lnGerman,
  lnSpanish,
} from "../../Routes/ImagePath";

import { FaRegBell, FaRegComment } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

import AuthUser from "../../components/AuthUser";

import { format } from "date-fns";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../components/Spinner";

const Header = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, getToken, logout, multipla_visibilidade, getMissao, getContacto } =
    AuthUser();

  const logoutUser = () => {
    console.log("Clicou para sair");

    if (getToken() != undefined) {
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    }
  };

  const navigate = useNavigate();

  const data = notifications.notifications;
  const datas = message.message;
  const [notificacaoNaoLida, setNotificacaoNaoLida] = useState(0);
  const [notification, setNotifications] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [flag, setflag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [flagImage, setFlagImage] = useState(lnAngola);

  const [loading, setLoading] = useState(false);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };

  const themes = localStorage.getItem("theme");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setNotifications(false);
    setProfile(false);
    setflag(false);
  };

  // const handleFlags = () => {
  //   setflag(!flag);
  //   setIsOpen(false);
  //   setNotifications(false);
  //   setProfile(false);
  // };

  const handleNotification = () => {
    setNotifications(!notification);
    setflag(false);
    setIsOpen(false);
    setProfile(false);
  };
  const handleProfile = () => {
    setProfile(!profile);
    setNotifications(false);
    setflag(false);
    setIsOpen(false);
  };

  const location = useLocation();
  let pathname = location.pathname;
  // const { value } = useSelector((state) => state.user);
  const Credencial = localStorage.getItem("credencial");
  const Value = JSON.parse(Credencial);
  const UserName = Value?.email?.split("@")[0];
  const ProfileName = UserName?.charAt(0).toUpperCase() + UserName?.slice(1);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    console.log(`Changing language to ${lng}`);
    i18n.changeLanguage(lng).then(() => {
      console.log(`Language changed successfully to ${lng}`);
    });

    // Debugging statement
    i18n.changeLanguage(lng);
    setFlagImage(
      lng === "en"
        ? lnEnglish
        : lng === "fr"
        ? lnFrench
        : lng === "es"
        ? lnSpanish
        : lng === "pt"
        ? lnAngola
        : lnGerman
    );
  };

  // eslint-disable-next-line
  useEffect(() => {
    console.log(getToken());

    if (getToken() == undefined) {
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    }

    changeLanguage("pt");
  }, []);

  const marcar_como_lidas = async () => {
    setLoading(true);
    const res = await http.get("/marcar-notificacaoes-lidas");
    setLoading(false);

    setNotifications(false);

    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const fetchNotificacoes = async () => {
    try {

      const res = await http.get("/minhas-notificacoes/"+getMissao()?.id);

      setAtividades(res.data.atividades);
      setNotificacoes(res.data.notificacoes);

      console.log("Resposta");
      console.log(res.data);

      console.log("Antes do filtro");
      console.log("atividades", atividades);
      console.log("notificacoes", notificacoes);

      // Filtra as atividades de acordo com as notificacões do utilizador
      const filteredActivities = res.data?.atividades?.filter((activity) => {
        return res.data?.notificacoes?.some(
          (notificacao) => notificacao.atividade_id == activity.id
        );
      });

      setNotificacaoNaoLida(
        res.data?.notificacoes?.filter((note) => note.estado == 0)?.length
      );

      // Atualiza o estado com as atividades filtradas
      setAtividades(filteredActivities);

      console.log("Depois do filtro");
      console.log("atividades", atividades);
      console.log("notificacoes", notificacoes);
    } catch (error) {
      console.error("Erro ao buscar notificacoes:", error);
    }
  };

  // useEffect para buscar as mensagens inicialmente e então configurar a atualização periódica
  useEffect(() => {
    fetchNotificacoes(); // Busca as mensagens inicialmente

    // Configura a atualização periódica a cada 5 segundos (5000ms)
    //const intervalId = setInterval(() => {
    //  fetchNotificacoes(); // Busca as mensagens periodicamente
    //}, 5000); // Ajuste o intervalo conforme necessário

    // Retorna uma função de limpeza para limpar o intervalo quando o componente é desmontado ou o useEffect é re-executado
    //return () => clearInterval(intervalId);
  }, []); // O array vazio indica que o useEffect só será executado uma vez após a montagem inicial do componente

  if (loading)
    return (
      <>
        <Spinner>
          <ToastContainer />
        </Spinner>
      </>
    );
  else
    return (
      <div className="header" style={{ right: "0px" }}>
        {/* Logo */}
        <div className="header-left">
          <Link to="/admin-dashboard" className="logo">
            <img src={url_root+'/storage/'+getContacto()?.logo} width={40} height={40} alt="img" />
          </Link>
          <Link to="/admin-dashboard" className="logo2">
            <img src={url_root+'/storage/'+getContacto()?.logo} width={40} height={40} alt="img" />
            
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="toggle_btn"
          to="#"
          style={{
            display: pathname.includes("compose") ? "none" : "",
          }}
          onClick={handlesidebar}
        >
          {/* <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
            ? "none"
            : "",
        }}
        onClick={handlesidebar}
      > */}
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Title */}
        <div className="page-title-box">
          <h3>PROJETO-V2024</h3>
          <span className="badge bg-inverse-danger">
              {" "}
              {t("Grupo")}
              {" : "}
              {getMissao()?.designacao}
            </span>
        </div>
        {/* /Header Title */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={() => onMenuClik()}
        >
          <i className="fa fa-bars" />
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item">
            <div className="top-nav-search">
              <Link to="#" className="responsive-search">
                <i className="fa fa-search" />
              </Link>
              <form>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("search here ...")}
                />
                <button className="btn" type="submit">
                  <i className="fa fa-search" />
                </button>
              </form>
            </div>
          </li>
          {/* /Search */}
          {/* Flag */}

          <li className="nav-item dropdown has-arrow flag-nav">
            <Link
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              to="#"
              role="button"
            >
              <img src={flagImage} alt="Flag" height="20" /> {t(i18n.language)}
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => changeLanguage("pt")}
              >
                <img src={lnAngola} alt="Flag" height="16" /> Português
              </Link>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => changeLanguage("en")}
              >
                <img src={lnEnglish} alt="Flag" height="16" /> Inglês
              </Link>
              {/* <Link
              to="#"
              className="dropdown-item"
              onClick={() => changeLanguage("fr")}
            >
              <img src={lnFrench} alt="Flag" height="16" /> Francês
            </Link>
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => changeLanguage("es")}
            >
              <img src={lnSpanish} alt="Flag" height="16" /> Espanhol
            </Link>
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => changeLanguage("de")}
            >
              <img src={lnGerman} alt="Flag" height="16" /> Alemão
            </Link> */}
            </div>
          </li>

          {/* Missões */}

          {multipla_visibilidade ? (
            <li className="nav-item dropdown" title="Alternar entre as missões">
              <Link to="/painel-missao" className="nav-link">
                <i className="fa fa-building"></i>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* /Missões */}

          {/* /Flag */}
          {/* Notifications */}
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
              onClick={handleNotification}
            >
              <i>
                <FaRegBell />
              </i>{" "}
              <span className="badge badge-pill">{notificacaoNaoLida}</span>
            </Link>
            <div
              className={`dropdown-menu dropdown-menu-end notifications ${
                notification ? "show" : ""
              }`}
            >
              <div className="topnav-dropdown-header">
                <span className="notification-title">{t("Notifications")}</span>
                <span
                  onClick={() => marcar_como_lidas()}
                  className="clear-noti"
                >
                  {t("clear all")}
                </span>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  {atividades?.slice(0, 10).map((activity, index) => {
                    return (
                      <li className="notification-message" key={index}>
                        <Link
                          onClick={() =>
                            localStorage.setItem("minheight", "true")
                          }
                          to={"/profile/" + activity?.user?.id}
                        >
                          <div className="media d-flex">
                            <span className="avatar flex-shrink-0">
                              <img
                                src={
                                  url_root +
                                  "/storage/" +
                                  activity?.user?.fotografia
                                }
                                alt={activity?.user?.name}
                                title={activity?.user?.name}
                              />
                            </span>
                            <div className="media-body">
                              <p className="noti-details">
                                <span className="noti-title">
                                  {activity?.user?.name}
                                </span>{" "}
                                <span className="noti-title">
                                  {/* content start */}

                                  {/* PROJETOS */}

                                  {/* Adicionou projeto */}
                                  {activity?.tipo == 1 &&
                                  activity?.accao == 1 &&
                                  activity?.receptor_id == 0
                                    ? " adicionou novo projeto, "
                                    : ""}

                                  {/* editou projeto */}
                                  {activity?.tipo == 1 && activity?.accao == 2
                                    ? " editou a informação do projeto " +
                                      activity?.mensagem +
                                      "  "
                                    : ""}

                                  {/* fechou projeto */}
                                  {activity?.tipo == 1 && activity?.accao == 9
                                    ? " fechou o projeto, " +
                                      activity?.mensagem +
                                      "  "
                                    : ""}

                                  {/* abriu projeto */}
                                  {activity?.tipo == 1 && activity?.accao == 10
                                    ? " voltou a abrir o projeto para execução, " +
                                      activity?.mensagem +
                                      "  "
                                    : ""}

                                  {/* prazo projeto */}
                                  {activity?.tipo == 1 && activity?.accao == 11
                                    ? " Alterou o prazo ( deadline ) do projeto, " +
                                      activity?.mensagem +
                                      "  "
                                    : ""}

                                  {/* adicionou colaborador ao projeto */}
                                  {activity?.tipo == 1 &&
                                    activity?.accao == 1 &&
                                    activity?.receptor_id != 0 && (
                                      <span>
                                        adicionou o colaborador{" "}
                                        <Link
                                          to={
                                            "/profile/" + activity?.receptor?.id
                                          }
                                          className="name"
                                        >
                                          {activity?.receptor?.name}
                                        </Link>{" "}
                                        ao projeto, {activity?.mensagem}
                                        {", "}
                                      </span>
                                    )}

                                  {/* removeu colaborador do projeto */}
                                  {activity?.tipo == 1 &&
                                    activity?.accao == 3 &&
                                    activity?.receptor_id != 0 && (
                                      <span>
                                        removeu o colaborador{" "}
                                        <Link
                                          to={
                                            "/profile/" + activity?.receptor?.id
                                          }
                                          className="name"
                                        >
                                          {activity?.receptor?.name}
                                        </Link>{" "}
                                        do projeto, {activity?.mensagem}
                                        {", "}
                                      </span>
                                    )}

                                  {/* Aprovar, reprovar projeto */}
                                  {activity?.tipo == 1 &&
                                    activity?.accao == 4 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        {activity?.mensagem} a execução do
                                        projeto{", "}
                                      </span>
                                    )}

                                  {/* Arquivar, desarquivar projeto */}
                                  {activity?.tipo == 1 &&
                                    activity?.accao == 6 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        {activity?.mensagem} o projeto{", "}
                                      </span>
                                    )}

                                  {/* eliminar projeto */}
                                  {activity?.tipo == 1 &&
                                    activity?.accao == 3 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        {"removeu o projeto " +
                                          activity?.mensagem}{" "}
                                      </span>
                                    )}

                                  {/* TAREFAS */}

                                  {/* adicionou tarefa ao projeto */}
                                  {activity?.tipo == 2 &&
                                    activity?.accao == 1 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        adicionou a tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.mensagem}
                                        </Link>{" "}
                                        ao projeto {", "}
                                      </span>
                                    )}

                                  {/* editou a tarefa do projeto */}
                                  {activity?.tipo == 2 &&
                                    activity?.accao == 2 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        editou a tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.mensagem}
                                        </Link>{" "}
                                        no projeto {", "}
                                      </span>
                                    )}

                                  {/* fechou tarefa */}
                                  {activity?.tipo == 2 && activity?.accao == 9
                                    ? " fechou a tarefa, " +
                                      activity?.tarefa?.nome +
                                      " no projeto,  "
                                    : ""}

                                  {/* abriu tarefa */}
                                  {activity?.tipo == 2 && activity?.accao == 10
                                    ? " voltou a abrir a tarefa " +
                                      activity?.tarefa?.nome +
                                      " no projeto,  "
                                    : ""}

                                  {/* prazo tarefa */}
                                  {activity?.tipo == 2 && activity?.accao == 11
                                    ? " Alterou o prazo ( deadline ) da tarefa " +
                                      activity?.tarefa?.nome +
                                      " no projeto,  "
                                    : ""}

                                  {/* eliminar tarefa */}
                                  {activity?.tipo == 2 &&
                                    activity?.accao == 3 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        {"removeu a tarefa " +
                                          activity?.mensagem}
                                        {" no projeto, "}
                                      </span>
                                    )}

                                  {/* adicionou colaborador na tarefa */}
                                  {activity?.tipo == 2 &&
                                    activity?.accao == 1 &&
                                    activity?.receptor_id != 0 && (
                                      <span>
                                        adicionou o colaborador{" "}
                                        <Link
                                          to={
                                            "/profile/" + activity?.receptor?.id
                                          }
                                          className="name"
                                        >
                                          {activity?.receptor?.name}
                                        </Link>{" "}
                                        a tarefa,{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {activity?.tarefa?.nome}
                                        </Link>
                                        {", no projeto "}
                                      </span>
                                    )}

                                  {/* removeu colaborador da tarefa */}
                                  {activity?.tipo == 2 &&
                                    activity?.accao == 3 &&
                                    activity?.receptor_id != 0 && (
                                      <span>
                                        removeu o colaborador{" "}
                                        <Link
                                          to={
                                            "/profile/" + activity?.receptor?.id
                                          }
                                          className="name"
                                        >
                                          {activity?.receptor?.name}
                                        </Link>{" "}
                                        da tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {activity?.tarefa?.nome}
                                        </Link>
                                        {", no projeto "}
                                      </span>
                                    )}

                                  {/* ITEMS DE TAREFA */}

                                  {/* adicionou item de tarefa a tarefa */}
                                  {activity?.tipo == 3 &&
                                    activity?.accao == 1 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        adicionou item de tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.item?.nome}
                                        </Link>{" "}
                                        a tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.tarefa?.nome}
                                        </Link>{" "}
                                        no projeto,
                                      </span>
                                    )}

                                  {/* editou a tarefa do projeto */}
                                  {activity?.tipo == 3 &&
                                    activity?.accao == 2 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        editou a informação do item de tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.item?.nome}
                                        </Link>{" "}
                                        a tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.tarefa?.nome}
                                        </Link>{" "}
                                        no projeto,
                                      </span>
                                    )}

                                  {/* fechou item de tarefa */}
                                  {activity?.tipo == 3 &&
                                  activity?.accao == 9 ? (
                                    <span>
                                      fechou o item de tarefa,{" "}
                                      {activity?.item?.nome} na tarefa{" "}
                                      <Link
                                        to={
                                          "/task/tasks/" + activity?.tarefa_id
                                        }
                                        className="name"
                                      >
                                        {" " + activity?.tarefa?.nome}
                                      </Link>{" "}
                                      do projeto{" "}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {/* abriu item de tarefa */}
                                  {activity?.tipo == 3 &&
                                  activity?.accao == 10 ? (
                                    <span>
                                      voltou a abrir o item de tarefa,{" "}
                                      {activity?.item?.nome} para execução na
                                      tarefa{" "}
                                      <Link
                                        to={
                                          "/task/tasks/" + activity?.tarefa_id
                                        }
                                        className="name"
                                      >
                                        {" " + activity?.tarefa?.nome}
                                      </Link>{" "}
                                      do projeto{" "}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {/* eliminar item de tarefa */}
                                  {activity?.tipo == 3 &&
                                    activity?.accao == 3 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        removeu item de tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.mensagem}
                                        </Link>{" "}
                                        na a tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {" "}
                                          {activity?.tarefa?.nome}
                                        </Link>{" "}
                                        do projeto,
                                      </span>
                                    )}

                                  {activity?.tipo == 6 &&
                                    activity?.accao == 2 &&
                                    activity?.projeto_id != 0 && (
                                      <span>
                                        Adicionou ficheiros ao projeto{" "}
                                        <Link
                                          to={
                                            "/project-view/" +
                                            activity?.projeto?.id
                                          }
                                        >
                                          {activity?.projeto?.nome}
                                        </Link>
                                      </span>
                                    )}

                                  {activity?.tipo == 6 &&
                                    activity?.accao == 3 &&
                                    activity?.projeto_id != 0 && (
                                      <span>
                                        Removeu ficheiros ao projeto{" "}
                                        <Link
                                          to={
                                            "/project-view/" +
                                            activity?.projeto?.id
                                          }
                                        >
                                          {activity?.projeto?.nome}
                                        </Link>
                                      </span>
                                    )}

                                  {/* TICKETS */}

                                  {/* Adicionou ticket */}
                                  {activity?.tipo == 4 &&
                                  activity?.accao == 1 &&
                                  activity?.receptor_id == 0 ? (
                                    <span>
                                      {" "}
                                      adicionou novo ticket,{" "}
                                      <Link
                                        to={
                                          "/task/ticket-view/" +
                                          activity?.tipo_id
                                        }
                                        className="name"
                                      >
                                        {activity?.mensagem}
                                        {", "}
                                      </Link>
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {/* editou ticket */}
                                  {activity?.tipo == 4 &&
                                  activity?.accao == 2 ? (
                                    <span>
                                      {" "}
                                      editou a informação do ticket,{" "}
                                      <Link
                                        to={
                                          "/task/ticket-view/" +
                                          activity?.tipo_id
                                        }
                                        className="name"
                                      >
                                        {activity?.mensagem}
                                        {", "}
                                      </Link>
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {/* fechou ticket */}
                                  {activity?.tipo == 4 &&
                                  activity?.accao == 9 ? (
                                    <span>
                                      {" "}
                                      fechou o ticket,{" "}
                                      <Link
                                        to={
                                          "/task/ticket-view/" +
                                          activity?.tipo_id
                                        }
                                        className="name"
                                      >
                                        {activity?.mensagem}
                                        {", "}
                                      </Link>
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {/* abriu ticket */}
                                  {activity?.tipo == 4 && activity?.accao == 10
                                    ? " voltou a abrir o ticket para execução, " +
                                      activity?.mensagem +
                                      "  "
                                    : ""}

                                  {/* prazo ticket */}
                                  {activity?.tipo == 4 &&
                                  activity?.accao == 11 ? (
                                    <span>
                                      {" "}
                                      Alterou o prazo ( deadline ) do ticket,{" "}
                                      <Link
                                        to={
                                          "/task/ticket-view/" +
                                          activity?.tipo_id
                                        }
                                        className="name"
                                      >
                                        {activity?.mensagem}
                                        {", "}
                                      </Link>
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {/* adicionou colaborador ao ticket */}
                                  {activity?.tipo == 4 &&
                                    activity?.accao == 1 &&
                                    activity?.receptor_id != 0 && (
                                      <span>
                                        adicionou o colaborador{" "}
                                        <Link
                                          to={
                                            "/profile/" + activity?.receptor?.id
                                          }
                                          className="name"
                                        >
                                          {activity?.receptor?.name}
                                        </Link>{" "}
                                        ao ticket,{" "}
                                        <Link
                                          to={
                                            "/task/ticket-view/" +
                                            activity?.tipo_id
                                          }
                                          className="name"
                                        >
                                          {activity?.mensagem}
                                          {", "}
                                        </Link>
                                      </span>
                                    )}

                                  {/* removeu colaborador do ticket */}
                                  {activity?.tipo == 4 &&
                                    activity?.accao == 3 &&
                                    activity?.receptor_id != 0 && (
                                      <span>
                                        removeu o colaborador{" "}
                                        <Link
                                          to={
                                            "/profile/" + activity?.receptor?.id
                                          }
                                          className="name"
                                        >
                                          {activity?.receptor?.name}
                                        </Link>{" "}
                                        do ticket,{" "}
                                        <Link
                                          to={
                                            "/task/ticket-view/" +
                                            activity?.tipo_id
                                          }
                                          className="name"
                                        >
                                          {activity?.mensagem}
                                          {", "}
                                        </Link>
                                      </span>
                                    )}

                                  {/* Aprovar, reprovar ticket */}
                                  {activity?.tipo == 4 &&
                                    activity?.accao == 4 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        {activity?.mensagem} a execução do
                                        ticket{" "}
                                        <Link
                                          to={
                                            "/task/ticket-view/" +
                                            activity?.tipo_id
                                          }
                                          className="name"
                                        >
                                          {activity?.mensagem}
                                          {", "}
                                        </Link>
                                      </span>
                                    )}

                                  {/* Arquivar, desarquivar ticket */}
                                  {activity?.tipo == 4 &&
                                    activity?.accao == 6 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        {activity?.mensagem} o ticket{" "}
                                        <Link
                                          to={
                                            "/task/ticket-view/" +
                                            activity?.tipo_id
                                          }
                                          className="name"
                                        >
                                          {activity?.mensagem}
                                          {", "}
                                        </Link>
                                      </span>
                                    )}

                                  {/* eliminar ticket */}
                                  {activity?.tipo == 4 &&
                                    activity?.accao == 3 &&
                                    activity?.receptor_id == 0 && (
                                      <span>
                                        {"removeu o ticket " +
                                          activity?.mensagem}{" "}
                                      </span>
                                    )}

                                  {/* NOME E LINK DO PROJETO, DATA E HORA */}

                                  {activity?.tipo == 1 ||
                                  activity?.tipo == 2 ||
                                  activity?.tipo == 3 ? (
                                    <Link
                                      to={
                                        "/project-view/" + activity?.projeto?.id
                                      }
                                    >
                                      {activity?.projeto?.nome}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {/* chat direto */}

                                  {activity?.tipo == 7 &&
                                    activity?.chat_para == 1 && (
                                      <span>
                                        <Link
                                          to={
                                            "/call/chat/1/" +
                                            activity?.receptor_id
                                          }
                                        >
                                          deixou mensagem no chat
                                        </Link>
                                      </span>
                                    )}

                                  {/* chat na tarefa */}
                                  {activity?.tipo == 7 &&
                                    activity?.chat_para == 2 && (
                                      <span>
                                        conversou no chat da tarefa{" "}
                                        <Link
                                          to={
                                            "/task/tasks/" + activity?.tarefa_id
                                          }
                                          className="name"
                                        >
                                          {activity?.tarefa?.nome}
                                        </Link>{" "}
                                        do projeto{" "}
                                        <Link
                                          to={
                                            "/project-view/" +
                                            activity?.projeto?.id
                                          }
                                        >
                                          {activity?.projeto?.nome}
                                        </Link>
                                      </span>
                                    )}

                                  {/* chat na ticket */}

                                  {activity?.tipo == 7 &&
                                    activity?.chat_para == 3 && (
                                      <span>
                                        conversou no chat do ticket{" "}
                                        <Link
                                          to={
                                            "/ticket-view/" + activity?.tipo_id
                                          }
                                        >
                                          {activity?.mensagem}
                                        </Link>
                                      </span>
                                    )}

                                  {/* content ends */}
                                </span>
                                {/* <span className="noti-title">
                            {val.contents_2}
                          </span> */}
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">
                                  {format(
                                    new Date(activity.data),
                                    "dd/MM/yyyy"
                                  ) +
                                    " " +
                                    activity.hora}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link
                  onClick={() => localStorage.setItem("minheight", "true")}
                  to="/app/administrator/activities"
                >
                  {t("View all Notifications")}
                </Link>
              </div>
            </div>
          </li>
          {/* /Notifications */}
          {/* Message Notifications */}
          {/*<li className={`nav-item dropdown ${isOpen ? "show" : ""}`}>
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={toggleDropdown}
          >
            <i>
              <FaRegComment />
            </i>{" "}
            <span className="badge badge-pill">0</span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end notifications ${isOpen ? "show" : ""
              }`}
          >
            <div className="topnav-dropdown-header">
              <span className="notification-title">{t('Messages')}</span>
              <Link to="#" className="clear-noti">
                {t('Clear All')}
              </Link>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {datas.map((value, index) => {
                  return (
                    <li className="notification-message" key={index}>
                      <Link
                        onClick={() =>
                          localStorage.setItem("minheight", "true")
                        }
                        to="/conversation/chat"
                      >
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">
                              <img alt="" src={value.image} />
                            </span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">{value.name}</span>
                            <span className="message-time">{value.time}</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              {value.contents}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })} 
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link
                onClick={() => localStorage.setItem("minheight", "true")}
                to="/conversation/chat"
              >
                {t('View all Messages')}
              </Link>
            </div>
          </div>
        </li> */}
          {/* /Message Notifications */}
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
              onClick={handleProfile}
            >
              {" "}
              <span className="user-img me-1">
                <img
                  src={url_root + "/storage/" + user.fotografia}
                  alt="foto"
                />
                <span className="status online" />
              </span>
              <span>{user ? `${user?.name.split(" ")[0]}` : "Admin"}</span>
            </Link>
            <div
              className={`dropdown-menu dropdown-menu-end ${
                profile ? "show" : ""
              }`}
            >
              <Link className="dropdown-item" to={"/profile/" + user?.id}>
                <i className="fa fa-user"></i> {t("My Profile")}
              </Link>
              {/* <Link className="dropdown-item" to="/settings/companysetting">
            {t('Settings')}
            </Link> */}
              <Link className="dropdown-item" to="/change-password">
                <i className="fa fa-key"></i> {t("Update Password")}
              </Link>
              {multipla_visibilidade ? (
                <Link to="/painel-missao" className="dropdown-item">
                  <i className="fa fa-building"></i> {t("Mission")}
                </Link>
              ) : (
                ""
              )}
              <Link to="/definicoes" className="dropdown-item">
                  <i className="fa fa-cogs"></i> {t("Definições")}
                </Link>
              <span className="dropdown-item" onClick={logoutUser}>
                <i className="fa fa-times"></i> {t("Logout")}
              </span>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}

        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>

          <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
            <Link className="dropdown-item" to={"/profile/" + user?.id}>
              <i className="fa fa-user"></i> {t("My Profile")}
            </Link>
            {/* <Link className="dropdown-item" to="/settings/companysetting">
          {t('Settings')}
          </Link> */}
            <Link className="dropdown-item" to="/change-password">
              <i className="fa fa-key"></i> {t("Update Password")}
            </Link>
            {multipla_visibilidade ? (
              <Link to="/painel-missao" className="dropdown-item">
                <i className="fa fa-building"></i> {t("Mission")}
              </Link>
            ) : (
              ""
            )}
            <span className="dropdown-item" onClick={logoutUser}>
              <i className="fa fa-times"></i> {t("Logout")}
            </span>
          </div>
        </div>
        {/* /Mobile Menu */}

        <ToastContainer />
      </div>
    );
};

export default Header;
